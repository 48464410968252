import React from "react"

const SIBTermsPage = () => {
  return (
    <>
      <section className="page-header bg-paper bg-tear-white">
        <div className="container">
          <div className="columns">
            <div className="column is-8-desktop is-offset-2-desktop">
              <h1>Terms of Use</h1>
              <p>Some of the small print that relates to the big stuff</p>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-8 is-offset-2">
              <div className="content content-terms">
                <h2 className="heading-large-alt">Legal Notice</h2>
                <p>
                  USE OF shakespeareinbits.com WEBSITE AND FORUMS LEGAL TERMS
                  AND CONDITIONS
                </p>
                <p>
                  Mindconnex Learning Limited, trading as Shakespeare In Bits,
                  makes shakespeareinbits.com available for use subject to the
                  legal conditions set out below.
                </p>
                <h3>1. Acceptance of Terms</h3>
                <p>
                  1.1. Your access to and use of shakespeareinbits.com ("the
                  Website") and the Services outlined in Clause 2, are subject
                  exclusively to these Terms and Conditions. You will not use
                  the Website/Services for any purpose that is unlawful or
                  prohibited by these Terms and Conditions. By using the
                  Website/Services you are fully accepting the terms, conditions
                  and disclaimers contained in this notice. If you do not accept
                  these Terms and Conditions you must immediately stop using the
                  Website/Services.
                </p>
                <p>
                  1.2. Shakespeare In Bits reserves the right to update or amend
                  these Terms and Conditions at any time and your continued use
                  of the Website/Services following any changes shall be deemed
                  to be your acceptance of such change. It is therefore your
                  responsibility to check the Terms and Conditions regularly for
                  any changes.
                </p>
                <h3>2. The Services</h3>
                <p>
                  The Website may provide communication tools such as email,
                  bulletin boards, chat areas, news groups, forums and/or other
                  message or communication facilities ("the Services") designed
                  to enable you to communicate with others. Unless stated
                  otherwise the Services are for your personal and
                  non-commercial use only.
                </p>
                <h3>3. Child Supervision</h3>
                <p>
                  Shakespeare In Bits is concerned about the safety and privacy
                  of its users, particularly children. Parents who wish to allow
                  their children access to and use of the Website/Services
                  should supervise such access and use. By allowing your child
                  access to the Services you are allowing your child access to
                  all of the Services, including email, bulletin boards, chat
                  areas, news groups, forums and/or other message or
                  communication facilities. It is therefore your responsibility
                  to determine which Services are appropriate for your child.
                  Always use caution when revealing personally identifiable
                  information about yourself or your children via any of the
                  Services.
                </p>
                <h3>
                  4. User Account, Password Account, Password and Security
                </h3>
                <p>
                  If a particular Service requires you to open an account you
                  will be required to complete the registration process by
                  providing certain information and registering a username and
                  password for use with the Services. You are responsible for
                  maintaining the confidentiality of the username and password
                  and also for all activities that take place under your
                  account. You agree to immediately notify Shakespeare In Bits
                  of any unauthorised use of your password or account or any
                  other breach of security. In no event will Shakespeare In Bits
                  be liable for any indirect or consequential loss or damage
                  whatsoever resulting from the disclosure of your username
                  and/or password. You may not use another person's account at
                  any time, without the express permission of the account
                  holder.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SIBTermsPage
